import React from "react"
import { Link } from "gatsby"
import { Image } from "theme-ui"

const Logo =() => (
  <div className="site-logo">
    <Link to="/">
      <Image src={"../../assets/pejudal-logo.png"} />
    </Link>
  </div>
)

export default Logo
